import { useRouter } from 'next/router';

type HookReturnType = {
  isHostCandidate: boolean;
  hostQuery: string;
};

/**
 * this is used for the flow where dst is opened from webview in the candidate mobile app
 * for non existent pages or selected user flows. We will use this to hide distracting
 * banners, modals and promotional messages when opened with the query param.
 * @returns {isHostCandidate: boolean, hostQuery: string} true if the query string contains the key 'host' and the value 'cdd-app'
 */
export const useHostCandidateApp = (): HookReturnType => {
  const QUERY_VALUE = 'cdd-app';
  const QUERY_KEY = 'host';
  const { query } = useRouter();

  return {
    isHostCandidate: query[QUERY_KEY] === QUERY_VALUE,
    hostQuery: `${QUERY_KEY}=${query[QUERY_KEY]}`,
  };
};
