import React from 'react';
import PropTypes from 'prop-types';

import ToggleButton from './ToggleButton';

const MobileRightMenu = props => <ToggleButton {...props} />;

MobileRightMenu.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  openSidebar: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool,
};

export default MobileRightMenu;
