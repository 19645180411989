import { Greyscale } from 'glints-aries';
import styled from 'styled-components';

import { MenuHeight } from 'src/styles/components/componentSize';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Greyscale.white};
  height: ${MenuHeight.mobile}px;
`;

export const ContainerRightContent = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerRightItem = styled.div`
  margin-right: 25px;
`;
