import React from 'react';
import { Greyscale, MenuIcon } from 'glints-aries';
import PropTypes from 'prop-types';

import { IconContainer, IconWrapper } from './ToggleButton.sc';

const ToggleButton = ({ sidebarOpen, closeSidebar, openSidebar }) => (
  <IconContainer
    onClick={sidebarOpen ? closeSidebar : openSidebar}
    role="navigation"
    aria-haspopup="true"
    aria-label="Open Sidebar Menu"
    tabIndex="0"
  >
    <IconWrapper tabIndex="-1">
      <MenuIcon color={Greyscale.black} />
    </IconWrapper>
  </IconContainer>
);

ToggleButton.propTypes = {
  openSidebar: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
  sidebarOpen: PropTypes.bool,
};

export default ToggleButton;
