import styled from 'styled-components';

export const IconWrapper = styled.div`
  font-size: 25px;
  outline: none;
`;

export const IconContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus > ${IconWrapper} {
    outline: 5px auto -webkit-focus-ring-color;
  }
`;
